.logo{
    width: 35px;
    height: auto;
}

/* Mặc định, hiển thị phần tử với class my-class */
.show_login_as_a_menu {
    display: block; /* Hiển thị phần tử mặc định */
}

/* Khi màn hình lớn hơn 768 pixels */
@media (min-width: 769px) {
    .show_login_as_a_menu {
        display: none; /* Ẩn phần tử */
    }
}
